import * as React from "react";
import { Link } from "gatsby";
import anyspherelogo from "../images/anysphere-logo3.svg";
import anyspherelogo2 from "../images/anysphere-logo4.svg";

const Footer = ({ absolute }: { absolute?: boolean }) => {
  const [hoveringlogo, setHoveringlogo] = React.useState(false);
  return (
    <>
      <footer
        className={absolute ? "absolute bottom-5 left-0 right-0" : "pb-5"}
      >
        <div className="grid place-content-center">
          <div className="grid-cols-3 items-center text-center text-xs text-[#DAD6C8] gap-12 grid">
            {/* <div>Anysphere</div> */}
            <a
              className="hover:text-[#fffa] text-[#fff]"
              href="mailto:hello@anysphere.co"
            >
              hello@anysphere.co
            </a>
            {/* <a
              href="/anysphere-whitepaper.pdf"
              className="hover:text-[#fffa] text-[#fff]"
            >
              Whitepaper
            </a> */}
            <Link
              to="/terms-of-service"
              className="hover:text-[#fffa] text-[#fff]"
            >
              Terms of Service
            </Link>
            <Link
              to="/privacy-policy"
              className="hover:text-[#fff] text-[#fff]"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
      {/* <hr className="border-asbrown-100"></hr>
      <footer className="w-full py-20 px-2 text-asbrown-dark">
        <div className="grid grid-cols-2 sm:grid-cols-4 items-top sm:justify-items-center gap-y-6 mx-4">
          <div className="grid grid-cols-1 content-start gap-2">
            <h3 className="font-bold text-lg">Anysphere</h3>
            <h6>© Copyright 2022</h6>
          </div>
          <div className="grid grid-cols-1 content-start gap-2">
            <h3 className="font-bold text-lg">Site</h3>
            <Link to="/" className="hover:underline">
              Home
            </Link>
            <Link to="/team" className="hover:underline">
              Team
            </Link>
            <Link to="/blog" className="hover:underline">
              Blog
            </Link>
          </div>
          <div className="grid grid-cols-1 content-start gap-2">
            <h3 className="font-bold text-lg">Legal</h3>
            <Link to="/privacy-policy" className="hover:underline">
              Privacy Policy
            </Link>
            <Link to="/terms-of-service" className="hover:underline">
              Terms of Service
            </Link>
          </div>
          <div className="grid grid-cols-1 content-start gap-2">
            <h3 className="font-bold text-lg">Connect</h3>
            <a href="https://twitter.com/anysphere" className="hover:underline">
              Twitter
            </a>
            <a className="hover:underline" href="mailto:hello@anysphere.co">
              hello@anysphere.co
            </a>
          </div>
        </div>
      </footer> */}
    </>
  );
};

export default Footer;
